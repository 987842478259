<template>
    <v-container id="dashboard" fluid tag="section">

        <v-text-field class="mt-4" v-on:keyup.enter="is_search=true,next()" label="اكتب للبحث" outlined
            append-icon="mdi-magnify" type="text" v-model="search_title">
        </v-text-field>
        <v-layout row wrap mt-4 pt-3 pb-3 pr-3 pl-3 ml-3>
            <v-flex xs12 sm4>
                <v-select v-model="filter.main_id" :items="videos_categoriesfilter" item-text="title" item-value="id"
                    label="القسم الرئيسي" required @change="filtersub=false;page=1;page=1; current_page=1;initialize()"
                    return-object persistent-hint v-on:input="initialize()"></v-select>
            </v-flex>
            <v-flex pr-4 xs12 sm4 v-if="subCats.length>0">

                <v-select v-model="filter.sub_main_id" :items="subCats" item-text="title" item-value="id"
                    label="القسم الفرعي" required @change="filtersub=true;page=1;page=1; current_page=1;initialize()"
                    v-on:input="initialize()"></v-select>
            </v-flex>
            <v-flex xs12 md2 pr-4>
                <v-btn color="blue" style="color:#fff"
                    @click="filter.main_id=null;initialize();;page=1;page=1; current_page=1">الكل</v-btn>
            </v-flex>
        </v-layout>
        <v-data-table :headers="headers" :items="desserts" sort-by="videos.id" class="elevation-1"
            :items-per-page="perpage">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title> {{ $t("Drawer.videos") }} </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="890px" persistent>
                        <v-toolbar color="primary" dark> {{ $t("add_new") }}</v-toolbar>

                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs"
                                v-on="on">
                                {{ $t("add_new") }}
                            </v-btn>
                        </template>
                        <v-stepper v-model="e1">
                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-card class="mb-12" flat>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-container>
                                                <v-row>
                                                    <v-col class="py-0" cols="12" sm="12" md="12">
                                                        <v-text-field v-model="editedItem.title" :rules="messageRules"
                                                            required :label="$t('datatable.title')" outlined>
                                                        </v-text-field>
                                                    </v-col>


                                                    <v-col class="py-0" cols="12" sm="12" md="12">
                                                        <v-textarea :rules="messageRules"
                                                            v-model="editedItem.description"
                                                            :label="$t('datatable.description')" outlined>
                                                        </v-textarea>
                                                    </v-col>


                                                    <v-col class="py-0" cols="12" sm="12" md="12">
                                                        <v-text-field v-model="editedItem.youtubeid" required
                                                            label="رابط البوتيوب" outlined>
                                                        </v-text-field>
                                                    </v-col>


                                                    <v-col class="py-0" cols="4" sm="12" md="4">
                                                        <v-switch v-model="editedItem.visible" flat
                                                            :label="(editedItem.visible)?'نشر':'اخفاء'"></v-switch>
                                                    </v-col>




                                                    <v-col class="py-0" cols="4" sm="12" md="4">
                                                        <v-switch v-model="editedItem.main_video" flat
                                                            :label="editedItem.main_video?'تظهور في الصفحه الرئيسية':'لا تظهور في الصفحه الرئيسية'">
                                                        </v-switch>



                                                    </v-col>

                                                </v-row>


                                                <v-radio-group v-model="editedItem.videos_categories[0]">

                                                    <v-row row wrap>

                                                        <v-col class="py-0" cols="12" sm="12" md="4"
                                                            v-for="cat in videos_categories" :key="cat"
                                                            v-if="cat.maincat_id==null">


                                                            <v-radio @change="upcat()" :label="cat.title" :value="cat">
                                                            </v-radio>


                                                        </v-col>
                                                    </v-row>

                                                </v-radio-group>

                                                <p v-if="editedItem.videos_categories.length >0"> اختر القسم الفرعي</p>



                                                <v-radio-group v-model="editedItem.videos_categories[1]"
                                                    v-if="editedItem.videos_categories.length >0">

                                                    <v-row row wrap>

                                                        <v-col cols="12" sm="12" md="4" v-for="cat in videos_categories"
                                                            :key="cat"
                                                            v-if="cat.maincat_id !== null && cat.maincat_id == editedItem.videos_categories[0].id">


                                                            <v-radio :label="cat.title" :value="cat"></v-radio>


                                                            
                                                        </v-col>
                                                    </v-row>

                                                </v-radio-group>

                                                <v-col cols="12" sm="6" md="5">
                                          
                                                    <input style="    border: 1px solid;" type="datetime-local" id="start" v-model="editedItem.date" placeholder="تاريخ النشر"
       
       >

                                           </v-col>




                                                <v-row row wrap v-if="editedIndex > -1 && editedItem.post !==null">
                                                    <v-col class="py-0" cols="12" sm="12" md="12">
                                                        <p>اسم المشارك : {{editedItem.post.name}}</p>
                                                        <p>هاتف المشارك : {{editedItem.post.phone}}</p>
                                                    </v-col>
                                                </v-row>


                                                <v-row row wrap>

                                                    <v-col cols="6" sm="12" md="6">



                                                        <v-text-field outlined label="أختر الصوره الرئيسية الفيديو "
                                                            @click='pickImg' v-model='imageName'
                                                            prepend-icon='mdi-paperclip'>
                                                        </v-text-field>
                                                        <input type="file" style="display: none" ref="image"
                                                            accept="image/*" @change="onFilePicked">


                                                        <div v-if="loadSave && !show_loading">
                                                            %{{percent_img}}

                                                            <progress :value="percent_img"></progress>

                                                        </div>


                                                    </v-col>


                                                    <v-col cols="6" sm="6" md="6" v-if="editedIndex == -1">


                                                        <v-text-field outlined label="أرفاق فيديؤ " @click='pickFile'
                                                            v-model='file_name' id="file" prepend-icon='mdi-paperclip'>
                                                        </v-text-field>

                                                        <input type="file" style="display: none" ref="file" id="file"
                                                            accept="application/application/,.mp4,"
                                                            v-on:change="onPickfile">
                                                    </v-col>


                                                    <v-col class="py-0" cols="12" sm="6" md="6">
                                                        <v-img :src="imageUrl" height="150" width="300"
                                                            v-if="imageUrl" />
                                                    </v-col>


                                                    <v-col class="py-0" cols="12" sm="6" md="6">



                                                        <div v-if="show_loading">

                                                            %{{percent}}

                                                            <progress :value="percent"></progress>


                                                            <!-- <p>يتم رفع الفديؤ</p>
                                                            <v-progress-circular indeterminate color="#f77d1f"
                                                                :size="150">
                                                            </v-progress-circular> -->
                                                        </div>
                                                    </v-col>

                                                    

                                                    <div v-if='editedIndex > -1'>
                                                       
                                                        <video width="320" height="240" controls v-if="editedItem.video_admin">
                                                   
                                                   <source  
                                                   v-if="editedItem.video_admin.filename"
                                                 
                                                   :src="'https://static1.alkafeel.tv/videos/'+xxxx(editedItem.video_admin.filename)+'/'+editedItem.video_admin.filename"
                                                 
                                                   type="video/mp4">
                                                 
                                                 </video>
                                                    </div>
                       


                                                </v-row>
                                                <v-row>
                            
                               
                                                </v-row>




                                            </v-container>



                                        </v-form>

                                    </v-card>

                                    <v-btn color="primary" :loading="loadSave" @click="save()">
                                        {{$t("save")}}
                                    </v-btn>

                                    <v-btn text @click="close()">
                                        {{$t("close")}}
                                    </v-btn>
                                </v-stepper-content>


                            </v-stepper-items>
                        </v-stepper>
                    </v-dialog>
                </v-toolbar>
            </template>


            <template v-slot:[`item.datex`]="{ item }">
         

{{ item.date }}
<!-- {{   new Intl.DateTimeFormat('en', {
                    year: 'numeric'
                }).format( new Date(item.date *1000)) }} -->
            </template>

            <template v-slot:[`item.cat`]="{ item }">

                <span v-for="cat in   item.videos_categories" :key="cat">
                    <span v-if="cat.relid !==0">{{cat.title}}/</span>
                </span>



            </template>

            <template v-slot:[`item.status`]="{ item }">

                <v-chip v-if="item.schedule_status==2" class="ma-2" style="color:#fff" color="blue">
                 مجدول
                </v-chip>

                <v-chip v-else-if="item.visible==1" class="ma-2" style="color:#fff" color="green">
                    تم النشر
                </v-chip>

             

                <v-chip v-else-if="item.visible==0" class="ma-2" style="color:#fff" color="red">
                    في الانتظار
                </v-chip>


            </template>


            <template v-slot:[`item.actions`]="{ item }">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <button class="ml-5" @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs"
                            v-on="on">تعديل</button>
                    </template>
                    <span>تعديل</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <button @click="deleteItem(item.id)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                            {{$t('Delete')}}</button>
                    </template>
                    <span>{{$t('Delete')}}</span>
                </v-tooltip>




            </template>

            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
            </template>
        </v-data-table>

        <div class="text-center pt-2">
            <!-- <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination> -->

            <!-- <v-pagination v-model="page" prev-icon="fas fa-angle-left fa-lg" color="#c7000b"
                next-icon="fas fa-angle-right fa-lg" style="    position: relative;top: 20px;" circle=""
                :length="pageCount">
            </v-pagination>
 -->

            <v-pagination v-model="page" :length="pageCount" @input="next"></v-pagination>


        </div>
    </v-container>


</template>

<script>
    import axios from "axios";
    import Swal from "sweetalert2";

    export default {

        name: "videos",
        data() {
            return {
                desserts: [],
                perpage: 15,
                percent: '',
                is_search: false,
                subCats: [],
                Cats: [],
                filter: {
                    main_id: null,
                    sub_main_id: null
                },
                file_name: null,
                e1: 1,
                imagesUrl: '',
                imageName: '',
                page: 1,
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

                menu2: false,
                pageCount: 0,
                current_page: 1,
                last_page: 0,
                gmValue: null,
                imageUrl: '',
                img_old: '',
                imageFile: '',
                percent_img: '',
                checkbox: true,
                imagesName: ' ',
                video_url: '',
                videos_categories: [],
                image_url: '',
                dialog: false,
                filtersub: false,
                videos_categoriesfilter: [],
                loadSave: false,
                show_loading: false,
                editedIndex: -1,
                editedItem: {
                    post: '',
                    image: {
                        image_name: ''
                    },
                    id: "",
                    title: '',
                    desc: '',
                    date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19),
                    visible: true,
                    videos_categories: [],
                    youtubeid: '',
                    main_video: false,
                    video_admin:{
                        filename:null
                    }


                },




                headers: [{
                        text: 'id',
                        value: 'id',
                    }, {
                        text: this.$t('datatable.title'),
                        align: "start",
                        value: 'title'
                    },
                    {
                        text: 'القسم',
                        value: "cat",
                        sortable: false
                    },

                    {
                        text: 'تاريخ الرفع',
                        align: "start",
                        value: "datex"
                    },
                    {

                        text: 'الحاله',
                        value: "status",
                        sortable: false
                    },
                    {
                        text: this.$t('Processes'),
                        value: "actions",
                        sortable: false
                    },

                ],
                right: null,
                vvv: {},
                items: [],
                search_title: '',


                valid: false,
                xxx:'',
                rules: {
                    required: value => !!value || this.$t("required_field"),
                },

                message: '',
                messageRules: [
                    // v => !!v || this.$t("valMessage")
                ],
            }
        },
        computed: {
      selected: function () {

        return this.getMoreitems();
      }
    },
    watch: {

selected: 'search by sub_cat_id',

},


        methods: {
            getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
   
          if(this.is_search){

          }else{
            this.initialize();
          }
         

        }
      },
            xxxx(vv){
             
                return vv.replace('.mp4', '');
            },


         

            next(page) {
if(this.is_search){
this.searchs(page);
}
                


             else   if (this.filter.main_id == null) {
             

this.loading = true;
try {
    axios.get('videos/admin?page=' +page, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
        }).then(res => {
            this.loading = false;
   
      for(var i=0;i<res.data.data.data.length;i++){
                                   
                        res.data.data.data[i].date=this.FormatDate(res.data.data.data[i].date);
                               }
                    this.desserts = res.data.data.data;
                    this.loading = false;
                    this.last_page = res.data.data.last_page;
                    this.pageCount = res.data.data.last_page;


            

        })
        .catch(() => {
            this.loading = false;
        });
} catch (e) {
    e
}

} else if (this.filter.main_id !== null && this.filtersub == false) {


axios.get("/videos/getByCatIdAdmin/" + this.filter.main_id.id + '/15?page=' + this.page).then(
    response => {

        this.loading = false;
        for(var i=0;i<response.data.data.data.length;i++){
               
            response.data.data.data[i].date=this.FormatDate(response.data.data.data[i].date);
           }
        this.desserts = response.data.data.data;
        this.last_page = response.data.data.last_page;
        this.pageCount = response.data.data.last_page;



        axios.get("/VideosCategories/subcat/" + this.filter.main_id.id)

            .then(res => {
                this.subCats = [];
                this.subCats = this.subCats.concat(res.data.data);

                this.subCats.push({
                    "id": 1000000000,
                    "title": "الكل",
                    "relid": "38",
                    "maincat_id": "38",
                    "langid": "1",
                    "date": "1669293712"
                });



            });





    })




} else if (this.filter.main_id.id !== null && this.filtersub == true) {
if (this.filter.sub_main_id == 1000000000) {
    axios.get("/videos/getByCatIdAdmin/" + this.filter.main_id.id + '/15?page=' + this.page).then(
        response => {

            this.loading = false;
            
            this.desserts = response.data.data.data;
            this.last_page = response.data.data.last_page;
            this.pageCount = response.data.data.last_page;







        });

    ///åååthis.sub_cats_fliter(); 
} else
    axios.get("/videos/getByCatIdAdmin/" + this.filter.sub_main_id + '/15?page=' + this.page).then(
        response => {




            this.loading = false;
            this.desserts = response.data.data.data;
            this.last_page = response.data.data.last_page;
            this.pageCount = response.data.data.last_page;


        })
}


            }


            ,
            searchs(page) {
            
                axios.get("/videos/searchs/" + this.search_title + '?page=' + page).then(response => {
                    for(var i=0;i<response.data.data.data.length;i++){
                                   
                        response.data.data.data[i].date=this.FormatDate(response.data.data.data[i].date);
                               }
                    this.desserts = response.data.data.data;
                    this.loading = false;
                    this.last_page = response.data.data.last_page;
                    this.pageCount = response.data.data.last_page;

                    alert('df')


                });
            },
            cropdate(unixTime) {


                let d = new Date(unixTime * 1000);

                let ye = new Intl.DateTimeFormat('en', {
                    year: 'numeric'
                }).format(d);

                let mo = new Intl.DateTimeFormat('en', {
                    month: '2-digit'
                }).format(d);

                let da = new Intl.DateTimeFormat('en', {
                    day: '2-digit'
                }).format(d);

                

                return (`${da}-${mo}-${ye}`);

                //  var originalFormat = new SimpleDateFormat("yyyyMMdd");
                var date = TimeSince(x)
                return date;
            },
            sub_cats_filter() {
                Fire.$emit('sub_cats_fliter');

            },
            cats_filter() {
                Fire.$emit('cats_fliter');


            },
            upcat() {

                this.editedItem.videos_categories = [];
                this.editedItem.videos_categories.push(this.editedItem.videos_categories[0]);
            },

            validate() {
                valid: true;
            },

            getCatsfilter() {
                try {
                    axios.get('videos/getCats', {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                            }
                        }).then(res => {

                            this.videos_categoriesfilter = res.data.data;

                        })
                        .catch(() => {

                        });
                } catch (e) {}

            },
            getCats() {
                try {
                    axios.get('videos/getCatsAll', {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                            }
                        }).then(res => {

                            this.videos_categories = res.data.data;

                        })
                        .catch(() => {

                        });
                } catch (e) {}

            },
            initialize() {


                if (this.filter.main_id == null) {

                    this.loading = true;
                    try {
                        axios.get('videos/admin?page=' + this.current_page, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                                }
                            }).then(res => {
                                this.loading = false;
                       
                                for(var i=0;i<res.data.data.data.length;i++){
                                   
                                   res.data.data.data[i].date=this.FormatDate(res.data.data.data[i].date);
                               }
                                this.desserts = res.data.data.data;
                                this.last_page = res.data.data.last_page;
                                this.pageCount = res.data.data.last_page;

                            })
                            .catch(() => {
                                this.loading = false;
                            });
                    } catch (e) {

                    }

                } else if (this.filter.main_id !== null && this.filtersub == false) {

                 
                    axios.get("/videos/getByCatIdAdmin/" + this.filter.main_id.id + '/15?page=' + this.page).then(
                        response => {

                            this.loading = false;
                            for(var i=0;i<response.data.data.data.length;i++){
                                   
                                response.data.data.data[i].date=this.FormatDate(response.data.data.data[i].date);
                               }
                            this.desserts = response.data.data.data;
                            this.last_page = response.data.data.last_page;
                            this.pageCount = response.data.data.last_page;



                            axios.get("/VideosCategories/subcat/" + this.filter.main_id.id)

                                .then(res => {
                                    this.subCats = [];
                                    this.subCats = this.subCats.concat(res.data.data);

                                    this.subCats.push({
                                        "id": 1000000000,
                                        "title": "الكل",
                                        "relid": "38",
                                        "maincat_id": "38",
                                        "langid": "1",
                                        "date": "1669293712"
                                    });



                                });





                        })




                } else if (this.filter.main_id.id !== null && this.filtersub == true) {
                    
                    if (this.filter.sub_main_id == 1000000000) {
                        axios.get("/videos/getByCatIdAdmin/" + this.filter.main_id.id + '/15?page=' + this.page).then(
                            response => {

                                this.loading = false;
                                
                                this.desserts = response.data.data.data;
                                this.last_page = response.data.data.last_page;
                                this.pageCount = response.data.data.last_page;







                            });

                        ///åååthis.sub_cats_fliter(); 
                    } else

        
                        axios.get("/videos/getByCatIdAdmin/" + this.filter.sub_main_id + '/15?page=' + this.page).then(
                            response => {




                                this.loading = false;
                                this.desserts = response.data.data.data;
                                this.last_page = response.data.data.last_page;
                                this.pageCount = response.data.data.last_page;


                            })
                }



            },

            FormatDatex: function (unixTime) {
                let d = new Date(unixTime );
                let ye = new Intl.DateTimeFormat('en', {
                    year: 'numeric'
                }).format(d);
                let mo = new Intl.DateTimeFormat('en', {
                    month: '2-digit'
                }).format(d);
                let da = new Intl.DateTimeFormat('en', {
                    day: '2-digit'
                }).format(d);

                let h = new Intl.DateTimeFormat('en', {
                    hour: '2-digit',
                    hour12: false,
                }).format(d);

                let m = new Intl.DateTimeFormat('en', {
                    minute: '2-digit',
                    
                }).format(d);
if(m.length==1){
    m=m+'0';
}

                return (`${ye}-${mo}-${da}T${h}:${m}`);
            }
,
            FormatDate: function (unixTime) {
                let d = new Date(unixTime *1000);
                let ye = new Intl.DateTimeFormat('en', {
                    year: 'numeric'
                }).format(d);
                let mo = new Intl.DateTimeFormat('en', {
                    month: '2-digit'
                }).format(d);
                let da = new Intl.DateTimeFormat('en', {
                    day: '2-digit'
                }).format(d);

                let h = new Intl.DateTimeFormat('en', {
                    hour: '2-digit',
                    hour12: false,
                }).format(d);

                let m = new Intl.DateTimeFormat('en', {
                    minute: '2-digit',
                    
                }).format(d);
if(m.length==1){
    m=m+'0';
}

                return (`${ye}-${mo}-${da}T${h}:${m}`);
            }
            
            ,
            editItem(item) {
              
             
                this.dialog = true;
                this.e1 = 1;

                item.visible = (item.visible == 1) ? true : false;
                item.main_video = (item.main_video == 1) ? true : false;

                this.editedIndex = this.desserts.indexOf(item);
                this.$store.dispatch("video_uploads", item);
     
          //   item.date=this.FormatDate(item.date);
               
                this.editedItem = Object.assign({}, item);
               
                this.img_old = ''
                this.imageName = ''
                this.imageFile = null
                this.imageUrl = ''
                


                this.img_old = this.editedItem.image.filename;
                this.imageUrl = this.Url + '/uploude/video_imgs/' + this.img_old;



            },
            pickFile() {
                this.$refs.file.click()
            },
            onPickfile(e) {

                this.file = this.$refs.file.files[0];
                let file = e.target.files;
                this.file = file[0]
                this.file_name = file[0].name
            },
            close() {
                //  this.filter.main_id=null;
                this.dialog = false;
                this.e1 = 1;
                if (this.filter.main_id == null) {
                    this.initialize();
                } else {
                    this.initialize();
                    this.sub_cats_filter();
                    this.cats_filter();



                }

                this.$store.dispatch("video_uploads", this.vvv);


                this.img_old = ''
                this.imageName = ''
                this.imageFile = ''
                this.imageUrl = ''

                this.editedItem = {

                    image: {
                        image_name: ''
                    },
                    id: "",
                    post: '',
                    title: '',
                    desc: '',
                    date:'',
                    videos_categories: [],
                    visible: true,
                    youtubeid: '',
                    video_admin:{
                        filename:null
                    },
                    main_video: false



                };

            },
            pickImg() {
                this.$refs.image.click()
            },
            pickFiles() {
                this.$refs.images.click()

            },
            onFilePicked(e) {
                const files = e.target.files
                if (files[0] !== undefined) {
                    this.imageName = files[0].name
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result
                        this.imageFile = files[0]
                    })
                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },
            deleteItem($id) {

                Swal.fire({
                    title: this.$t('sure_process'),
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then(result => {
                    if (result.value) {
                        axios.delete("videos/" + $id, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                                }
                            })
                            .then(() => {
                                this.close();
                                Swal.fire(this.$t('Successfully'), this.$t('done'), "success");
                            })
                            .catch(() => {
                                Swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
                            });
                    }
                });
            },

            save() {

                if (this.$refs.form.validate()) {

                    this.loadSave = true;

                    const date = new Date(this.editedItem.date);
                    var date2=date.getTime();
                   
                    date2=String(date2).slice(0, 10);
                   



                    if (this.imageFile == '') {
                        this.loadSave = false;
                        Swal.fire({

                            title: "يجب اختيار صوره",
                            text: "",
                            icon: "error",
                            confirmButtonText: "اغلاق",
                        });
                    }

                    if (this.editedIndex > -1) {


                        this.axios
                            .put("videos/update/" + this.editedItem.id, {
                                    title: this.editedItem.title,
                                    description: this.editedItem.description,
                                    date:Number(date2),
                                    youtubeid: this.editedItem.youtubeid,
                                    main_video: (this.editedItem.main_video) ? 1 : 0,
                                    visible: (this.editedItem.visible) ? 1 : 0,
                                    videos_categories: this.editedItem.videos_categories
                                }


                                , {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Accept: "application/json",
                                        Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                    },
                                })
                            .then(() => {

                                if (this.imageFile != null) {



                                    let formData = new FormData();
                                    const AuthStr = 'Bearer ' + this.token;
                                    formData.append('image_url', this.imageFile);

                                    axios.post('videos/uploadImage/' + this.editedItem.id,

                                            formData, {
                                                headers: {
                                                    "Content-Type": "application/json",
                                                    Accept: "application/json",
                                                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                                },
                                            }
                                        ).then(() => {

                                            this.$refs.form.resetValidation();
                                            this.close();
                                            // this.initialize();
                                            Swal.fire({
                                                title: "تم تعديل ",
                                                text: "",
                                                icon: "success",
                                                confirmButtonText: "اغلاق",
                                            });
                                            this.show_loading = false;
                                            this.file_name = '';
                                            this.file = '';
                                            this.dialog = false;
                                            //  this.e1 = 2
                                            this.loadSave = false;

                                        })

                                        .catch(() => {
                                            this.loadSave = false;
                                            this.$swal.fire({
                                                title: "حدث خطا .. يرجي المحاوله لاحقا",
                                                text: "",
                                                icon: "error",
                                                confirmButtonText: "اغلاق",
                                            });
                                        });

                                } else {
                                    this.$refs.form.resetValidation();
                                    this.close();
                                    // this.initialize();
                                    Swal.fire({
                                        title: "تم تعديل ",
                                        text: "",
                                        icon: "success",
                                        confirmButtonText: "اغلاق",
                                    });
                                    this.show_loading = false;
                                    this.file_name = '';
                                    this.file = '';
                                    this.dialog = false;
                                    //  this.e1 = 2
                                    this.loadSave = false;
                                }
                            })
                            .catch(() => {
                                this.loadSave = false;
                                this.$swal.fire({
                                    title: "تاكد من ملى المعلومات",
                                    text: "",
                                    icon: "error",
                                    confirmButtonText: "اغلاق",
                                });
                            });
                    } else {

                        // if (this.file == null) {
                        //     this.loadSave = false;
                        //     Swal.fire({
                        //         title: this.$t('sure_process'),
                        //         text: " يجب اختيار الفديو",
                        //         icon: "warning",
                        //         showCancelButton: true,
                        //         confirmButtonColor: "#3085d6",
                        //         cancelButtonColor: "#d33",
                        //     });
                        // }





                        axios
                            .post("videos/save", {
                                    title: this.editedItem.title,
                                    description: this.editedItem.description,
                                    youtubeid: this.editedItem.youtubeid,
                                    date:Number(date2),
                                    main_video: (this.editedItem.main_video) ? 1 : 0,
                                    visible: (this.editedItem.visible) ? 1 : 0,
                                    videos_categories: this.editedItem.videos_categories
                                }, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Accept: "application/json",
                                        Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                    },
                                }


                            )
                            .then((res) => {


                                // this.editedItem = res.data.data;

                                var x = this.editedItem.videos_categories;
                                this.editedItem = res.data.data;
                                this.editedItem.videos_categories = x;

                                this.editedIndex = -1;
                                this.savedate = {};
                                if (this.imageFile != null) {
                                    let formData = new FormData();
                                    const AuthStr = 'Bearer ' + this.token;
                                    formData.append('image_url', this.imageFile);




                                    var _this = this;

                                    axios.post('videos/uploadImage/' + this.editedItem.id, formData, {
                                            headers: {
                                                Authorizations: "Bearer " + this.$store.state
                                                    .AdminInfo.token,
                                                'Content-Type': 'multipart/form-data',
                                            },
                                            onUploadProgress: function (uploadEvent) {

                                                _this.percent_img = Math.round((uploadEvent
                                                        .loaded / uploadEvent
                                                        .total) *
                                                    100);


                                            }
                                        }




                                    ).then(() => {
                                        this.imageFile = '';
                                        this.imageName = '';
                                        this.img_old = '';
                                        this.imageUrl = "";



                                        if (this.file_name != null) {
                                            this.show_loading = true;

                                            const AuthStr = 'Bearer ' + this.token;
                                            let formDataFile = new FormData();
                                            formDataFile.append('video_url', this.file);
                                            var _this = this;
                                            axios.post('videos/uploadVideoxx/' + this.editedItem.id,
                                                    formDataFile, {
                                                        // maxBodyLength   : Infinity,
                                                        // maxContentLength: Infinity,
                                                        // timeout: 100000000000000000000000000000,
                                                        headers: {
                                                            Authorizations: "Bearer " + this.$store.state
                                                                .AdminInfo.token,
                                                            'Content-Type': 'multipart/form-data',
                                                        },

                                                        onUploadProgress: function (uploadEvent) {

                                                            _this.percent = Math.round((uploadEvent
                                                                    .loaded / uploadEvent
                                                                    .total) *
                                                                100);


                                                        }
                                                    }

                                                )
                                                .then(response => {

                                                    response
                                                    this.show_loading = false;
                                                    this.file_name = '';
                                                    this.file = '';
                                                    this.dialog = false;
                                                    this.loadSave = false;
                                                    this.close();
                                                    Swal.fire({
                                                        title: 'تم رفع الفديو',
                                                        text: "",
                                                        icon: "success",
                                                        confirmButtonText: this.$t('close'),
                                                    });
                                                })

                                                .catch(() => {
                                                    this.loadSave = false;
                                                    this.$swal.fire({
                                                        title: "حدث خطا .. يرجي المحاوله لاحقاً",
                                                        text: "",
                                                        icon: "error",
                                                        confirmButtonText: "اغلاق",
                                                    });
                                                });


                                            this.imageFile = '';
                                            this.imageName = ''

                                        } else {

                                            this.loadSave = false;
                                            Swal.fire({
                                                title: this.$t('sure_process'),
                                                text: " يجب اختيار الفديو",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                            });
                                        }
                                        this.$refs.form.resetValidation();



                                    }).catch(() => {
                                        this.loadSave = false;
                                        this.$swal.fire({
                                            title: "حدث خطا .. يرجي المحاوله لاحقاً",
                                            text: "",
                                            icon: "error",
                                            confirmButtonText: "اغلاق",
                                        });
                                    });
                                }
                            })
                    }
                }
            },
            components: {

            },
            computed: {

            },


        },
        created() {

            this.getCatsfilter();
            // Fire.$on('sub_cats_fliter', () => {
            //     if (this.filter.sub_main_id == 1000000000) {
            //         axios.get("/videos/getByCatId/" + this.filter.main_id.id + '/15?page=' + this.page).then(
            //             response => {

            //                 this.loading = false;
            //                 this.desserts = response.data.data.data;
            //                 this.last_page = response.data.data.last_page;
            //                 this.pageCount = response.data.data.last_page;







            //             });

            //         ///åååthis.sub_cats_fliter(); 
            //     } else
            //         axios.get("/videos/byCat/" + this.filter.sub_main_id + '/15?page=' + this.page).then(
            //             response => {




            //                 this.loading = false;
            //                 this.desserts = response.data.data.data;
            //                 this.last_page = response.data.data.last_page;
            //                 this.pageCount = response.data.data.last_page;


            //             })

            // });

            // Fire.$on('cats_fliter', () => {

            //     axios.get("/videos/getByCatId/" + this.filter.main_id.id + '/15?page=' + this.page).then(
            //         response => {

            //             this.loading = false;
            //             this.desserts = response.data.data.data;
            //             this.last_page = response.data.data.last_page;
            //             this.pageCount = response.data.data.last_page;



            //             axios.get("/VideosCategories/subcat/" + this.filter.main_id.id)

            //                 .then(res => {
            //                     this.subCats = [];
            //                     this.subCats = this.subCats.concat(res.data.data);
            //                     this.subCats.push({
            //                         "id": 1000000000,
            //                         "title": "الكل",
            //                         "relid": "38",
            //                         "maincat_id": "38",
            //                         "langid": "1",
            //                         "date": "1669293712"
            //                     });



            //                 });





            //         })

            // });


            this.next();
            this.getCats();
        }

    }
</script>